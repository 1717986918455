import React from 'react';
import { motion } from 'framer-motion';
import { useUnmount, useMount } from 'react-use';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faPhone, faPhoneOffice, faPhoneRotary } from '@fortawesome/pro-duotone-svg-icons';
import Banner from '../../shared-components/banner/Banner'
import Carousel from './shared-components/carousel/Carousel';
import Services from '../../shared-components/services/Services';
import StoreDialog from './shared-components/StoreDialog';
import { locationAtom, popupAtom, storesDialogAtom } from '../../../atoms/Atoms';
import BrandCard from '../../shared-components/brand-card/BrandCard';
import FeedbackFooter from '../../shared-components/feedback-footer/FeedbackFooter';
import envConfig from '../../../envConfig';
import BannerCarousel from '../../shared-components/banner-carousel/BannerCarousel';
import ServicesDrawer from '../../shared-components/service-selection/shared-components/ServicesDrawer';
import Register from '../../shared-components/locations/shared-components/register/Register';
import InfoFooter from '../../shared-components/info-footer/InfoFooter';
import ImageCarousel from '../../shared-components/image-carousel/ImageCarrousel';
import SocialFooter from '../../shared-components/social-footer/SocialFooter';
import GopanzaFooter from '../../shared-components/gopanza-footer/GopanzaFooter';
import Sponsors from '../../shared-components/sponsors/Sponsors';
import BannerV2 from '../../shared-components/banner/BannerV2';

function Home() {
  const setDialog = useSetRecoilState(storesDialogAtom);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);
  const [showPopup, setShowPopup] = useRecoilState(popupAtom);
  const width = window.innerWidth;

  useUnmount(() => {
    setDialog(false);
  });

  useMount(() => {
    setShowPopup(true);
  });

if (envConfig.TemplateVersion === '1') { 
  return (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  > <Banner 
    title="@mecacomplex"
    image="bg-courts"
  />
    <ServicesDrawer />   
    <Services />  
    {/* <ImageCarousel /> */}
    <FeedbackFooter />
    <Sponsors />
    <SocialFooter />
    <InfoFooter />
    <GopanzaFooter /> 
     
  </motion.div>
  </div>)
} if (envConfig.TemplateVersion === '2') {
  return (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <StoreDialog />
    {registerLocation.current === 'register-location' && <Register />}
    <div className="flex flex-col px-2 sm:px-12 md:px-24 lg:px-52">
      {/* <DynamicBanner />   */}
      <BannerCarousel />
      <StoreDialog />
      <Services />     
      <Carousel />       
      <BrandCard />
    </div>
    <FeedbackFooter />
  </motion.div>
  </div>)
} if (envConfig.TemplateVersion === '10') {
  return (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  > <BannerV2
    title={<div>
            <div><FontAwesomeIcon className="h-8" icon={faInstagram} /> @mecacomplex</div>
            <div>
              <button type="button" className="h-8" onClick="tel:7874374117" disabled={width > 1024}>
                <FontAwesomeIcon icon={faPhoneRotary} />{' '}<b>939.437.4117</b>
              </button>
            </div>
           </div>}
  />
  <div>
    <button type="button" className="h-8 lg:text-3xl" onClick="tel:7874374117" disabled={width > 1024} style={{ marginLeft: '15px' }}>
      <FontAwesomeIcon icon={faPhoneRotary} />{' '}<b>939.437.4117</b>
    </button>
  </div>
    <ServicesDrawer />   
    <Services />  
    {/* <ImageCarousel /> */}
    <FeedbackFooter />
    <Sponsors />
    <SocialFooter />
    <InfoFooter />
    <GopanzaFooter /> 
     
  </motion.div>
  </div>
  ) 
} return (
  <div className="flex-1 overflow-y-auto bg-white">
  <motion.div
    initial={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    animate={{
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
    exit={{
      y: 100,
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96],
      },
    }}
  >
    <ServicesDrawer />
    <ImageCarousel />
    <Services />  
    <FeedbackFooter />
    <SocialFooter />
    <InfoFooter />
    <GopanzaFooter /> 
     
  </motion.div>
  </div>)
}

export default Home;
